const key = 'mjkl54sghlk';

// Utility to generate a random salt
const generateSalt = (len: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let salt = '';
  for (let i = 0; i < len; i++) {
    salt += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return salt;
};

// Convert the key into a simple numeric hash
const getKeyHash = (key: string) => {
  return key
    .split('')
    .reduce((hash, char) => hash + char.charCodeAt(0), 0); // Sum of char codes
};

// Helper function to encode data as Base64-like string
const encodeToBase64 = (data: number[]) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'; // Base64 charset
  return data
    .map((value) => characters.charAt(value % 64)) // Map numeric values to Base64 characters
    .join(''); // Combine into a string
};

// Encrypt a string using salt and a string key
export const obfuscate = (plaintext: string) => {
  const salt = generateSalt(3); // Generate a 3-character salt
  const saltedText = salt + plaintext; // Add salt to the plaintext
  const keyHash = getKeyHash(key); // Generate numeric hash from the key

  // XOR each character of the salted text with the key hash
  const encrypted = saltedText
    .split('')
    .map((char) => char.charCodeAt(0) ^ keyHash); // Numeric array after XOR

  const encoded = encodeToBase64(encrypted); // Convert numeric array to Base64-like string
  return `${salt}${encoded}`; // Combine salt and encoded text
};

const obfuscateNumberOfPeople = (numberOfPeople: number | undefined): string => {
  if (numberOfPeople === 0) {
    return 'e60a1da5-6e37-4060-b32c-d96bc3562902';
  } else if (numberOfPeople === 1) {
    return '6db93662-558c-4b27-a702-89a5c1f5b5fa';
  } else if (numberOfPeople === 2) {
    return '63d8-46a8-9c85-74623fbda056';
  } else if (numberOfPeople && numberOfPeople >= 3) {
    return 'd43ffafe-4b72-450e-9584-55191b69b7b2';
  }
  return 'bb4e92b8-e221-40c6-8381-ae2cb2774f0d';
};

export const obfuscatePresence = (
  {peoplePresent, numberOfPeople}: {peoplePresent: boolean | undefined, numberOfPeople: number | undefined},
) => {
  return {
    '999a2d06-d2e5-405d-8e81-f9b0032ed96c':
      peoplePresent ? 'de3ff25f-dd0f-4fb2-8539-51ebbc2e09fd' : '28384901-761a-4ee1-ac04-39679bc2b6d1',
    'f3e68feb-a59b-40f8-8c0a-49f9ca3c1816': obfuscateNumberOfPeople(numberOfPeople),
  };
};
