import {AdTypes} from 'features/adoppler';
import {logger as baseLogger} from 'shared/utils/logger';

import {getAdPodFromCache, getRandomItem} from './helpers';

import type {WrappedResponse} from 'features/adoppler';

const logger = baseLogger.child({tag: '[Adoppler AD Requestor]'});

const getEmptyResponse = (): WrappedResponse => ({
  type: AdTypes.DefaultTelly,
  response: null,
  dataUpdatedAt: Date.now(),
});

export const getInHouseAd = async (
  adType: AdTypes,
): Promise<WrappedResponse> => {
  try {
    const response = await getRandomItem(adType);
    if (!response) {
      logger.warn('No InHouseAd found.');
      return getEmptyResponse();
    }

    logger.debug('InHouseAd fetched successfully', response);

    return {type: adType, response, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error fetching InHouse Ad', error);
    return getEmptyResponse();
  }
};

export const getAd = async (
  adType: AdTypes,
): Promise<WrappedResponse> => {
  try {
    const parsedResponse = await getRandomItem(adType);
    if (!parsedResponse) {
      logger.warn(`No Adoppler response for type ${adType}.`);
      return getEmptyResponse();
    }

    logger.debug('Received Adoppler response', parsedResponse);
    // INFO: For debugging change data to stub response here.
    return {type: adType, response: parsedResponse, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error requesting Adoppler ad', error);
    return getEmptyResponse();
  }
};

export const getAdPod = async (
  duration: number,
): Promise<WrappedResponse> => {
  try {
    const parsedResponse = await getAdPodFromCache(duration);

    if (!parsedResponse) {
      logger.warn(`No AdPod found in cache for duration ${duration}.`);
      return getEmptyResponse();
    }

    logger.debug('Received AdPod response', parsedResponse, duration);

    return {type: AdTypes.AdPod, response: parsedResponse, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error requesting Adoppler AdPod', error);
    return getEmptyResponse();
  }
};

export const getAvailableTypeInTheCache = async (
  adTypes: AdTypes[],
  waitLimit: number,
): Promise<AdTypes> => {
  const retryInterval = 100;
  let waitInterval = 0;

  return new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      try {
        for (const adType of adTypes) {
          const cacheInstance = await caches.open(`cache-${adType}`.toLowerCase());
          const cacheItems = await cacheInstance.keys();

          if (cacheItems.length > 0) {
            clearInterval(intervalId);
            resolve(adType);
            return;
          }
        }

        if (waitInterval >= waitLimit) {
          clearInterval(intervalId);
          resolve(AdTypes.DefaultTelly);
        }

        waitInterval += retryInterval;
      } catch (error) {
        clearInterval(intervalId);
        logger.warn('Error in getAvailableTypeInTheCache', error);
        resolve(AdTypes.DefaultTelly);
      }
    }, retryInterval);
  });
};
