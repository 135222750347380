import {useRef} from 'react';

import {meteEnvConfig} from 'config';
import {useAppContext, useMuteVideoContext} from 'context';

/**
 * Hook to manage mute state from various sources.
 *
 * @return {React.MutableRefObject<boolean>} mutedStateRef - Muted state reference
 */
export const useMute = (): React.MutableRefObject<boolean> => {
  const {deviceProperties} = useAppContext();

  const isVideoMutedByDefault = deviceProperties?.is_video_muted ?? Boolean(meteEnvConfig.ads.video.mute);
  const staticMutedRef = useRef<boolean>(false);

  const {isSoundMuted} = useMuteVideoContext();

  staticMutedRef.current = isSoundMuted ?? isVideoMutedByDefault;

  return staticMutedRef;
};
