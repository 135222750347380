import {useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

import {useAppContext} from 'context/index.ts';

import type {AdUnitType} from 'types';

import {baseUrl} from './constants.ts';

import type {UseQueryResult} from '@tanstack/react-query';
import type {UserBodyRequest} from 'features/adoppler/types.ts';

type Provider = 'elemental'

const getAdProxyUrl = (provider: Provider, adUnit: AdUnitType) => {
  // INFO:
  // For development may used be "return `https://app.freetelly.com/ads/processBid/37176090`;"
  // for fetching ad-pods
  return `${baseUrl}/${provider}/${adUnit}`;
};

const getAdProxyArcUrl = (provider: Provider) => {
  return `${baseUrl}/${provider}/EVE_FULLSCREEN_UNIT_ACR`;
};

/**
 * Fetch eids from backend service API.
 *
 * @param {string} [ifa] - IFA ID to retrieve config for
 * @return {Promise<AdConfig>} Promise resolving to eids
 */
export const getEids = async (ifa?: string): Promise<UserBodyRequest> => {
  return axios
    .get(`${baseUrl}/v1/eids/${ifa}`, {timeout: 5000})
    .then((res) => res.data);
};

/**
 * React hook to fetch eids.
 * @return {UseQueryResult<AdConfig>} Query result for eids
 */
export const useGetEids = (): UseQueryResult<UserBodyRequest | undefined> => {
  const queryClient = useQueryClient();
  const {deviceProperties} = useAppContext();
  const ifa = deviceProperties?.ad_info?.ifa;

  return useQuery({
    queryKey: ['eids'],
    queryFn: () => getEids(ifa),
    retry: 3,
    enabled: !!deviceProperties,
    networkMode: 'online',
    initialData: () => queryClient.getQueryData(['eids']) || undefined,
  });
};

export {
  getAdProxyUrl,
  getAdProxyArcUrl,
};
