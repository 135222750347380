import {parseVast} from 'features/vast-tracker/service';
import {logger as baseLogger} from 'shared/utils/logger';

import type {UseAdReturnType} from 'types';

import type {VastParsedData} from 'types/vast-client';

const logger = baseLogger.child({tag: '[prefetch Component]'});

Element.prototype.remove = function() {
  this.parentElement?.removeChild(this);
};

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
  for (let i = this.length - 1; i >= 0; i--) {
    this[i]?.parentElement?.removeChild(this[i]);
  }
};

/**
 * Prefetches ad pods by retrieving VAST URLs and filtering successful results.
 * @param {UseAdReturnType} param0 - The ad settings to use for prefetching.
 * @return {Promise<VastParsedData[]>} - The successfully parsed VAST data.
 */
export const prefetchAdPod = async ({adSettings}: UseAdReturnType):
  Promise<VastParsedData[]> => {
  const adUrls = adSettings.map((n) => n.adUrls).flat(1);
  const vasts = await getVast(adUrls as string[]);

  return vasts
    .filter(({status}) => status === 'fulfilled')
    .map((p) => (p as PromiseFulfilledResult<VastParsedData>).value);
};

/**
 * Fetches VAST data for the provided ad URLs.
 * @param {string[]} adUrls - The URLs to retrieve VAST data from.
 * @return {Promise<PromiseSettledResult<VastParsedData>[]>} - The VAST data or empty array if all requests fail.
 */
export const getVast = async (adUrls: string[]):
  Promise<PromiseSettledResult<VastParsedData>[]> => {
  const promises = adUrls.map(async (adUrl) => {
    return parseVast(adUrl).then((vastData) => {
      // append vast data only for current response id
      if (vastData[0]) {
        doPrefetch(vastData[0]);
        logger.debug(`VAST prefetch initiated for URL: ${adUrl}`);
      }
      return vastData;
    });
  });

  const results = await Promise.allSettled(promises);
  const allRejected = results.every((result) =>
    result.status === 'rejected');
  if (allRejected) {
    logger.warn('All VAST requests failed. HTTP errors encountered.', results);
    return [];
  }
  return results;
};

/**
 * Creates a 'prefetch' link element to cache the video file.
 * @param {string} videoURL - The URL of the video to prefetch.
 */
export function doPrefetch(videoURL: string) {
  const link = document.createElement('link');
  link.setAttribute('data-type', 'adpod');
  link.as = 'video/mp4';
  link.rel = 'prefetch';
  link.onload = () => {
    link.remove();
    logger.debug(`Prefetch link successfully loaded and removed for: ${videoURL}`);
  };
  link.href = videoURL;
  document.head.appendChild(link);
  logger.debug(`Prefetch link created and added to the document head for: ${videoURL}`);
}
