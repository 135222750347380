import {useEffect, useState} from 'react';

import {eventEmitter} from 'shared/utils';

const UNMUTE_EVE_EVENT = 'unmute_eve_event';
const MUTE_EVE_EVENT = 'mute_eve_event';

/**
 * Temporary solution to debug mute|unmute video
 * @return {boolean}
 */
export const useDebugMute = (): boolean => {
  const [mute, setMute] = useState(true);

  useEffect(() => {
    eventEmitter.on(UNMUTE_EVE_EVENT, () => {
      setMute(false);
    });
    eventEmitter.on(MUTE_EVE_EVENT, () => {
      setMute(true);
    });

    window.gabriel = {
      ...window.gabriel,
      unmute() {
        eventEmitter.emit(UNMUTE_EVE_EVENT);
      },
      mute() {
        eventEmitter.emit(MUTE_EVE_EVENT);
      },
    };

    return () => {
      eventEmitter.removeAllListeners(UNMUTE_EVE_EVENT);
      eventEmitter.removeAllListeners(MUTE_EVE_EVENT);
    };
  }, []);

  return mute;
};
