import {gabrielAnalyticMetrics} from 'shared/api/metrics-service';

/**
 * Checks the current internet connection and sends bandwidth metrics if needed.
 * @param {number} currentTime - The current playback time in seconds.
 * @param {React.MutableRefObject<NavigatorConnection>} connectionRef - A ref holding the previous connection info.
 * @param {number} HEARTBEAT_INTERVAL - Interval (in seconds) at which to send heartbeat.
 * @param {number} LOW_BANDWIDTH_THRESHOLD - Threshold (in Mbps) for low bandwidth.
 */
export default function checkAndSendInternetSpeedMetrics(
  currentTime: number,
  connectionRef: React.MutableRefObject<Required<NavigatorConnection>>,
  HEARTBEAT_INTERVAL: number = 5,
  LOW_BANDWIDTH_THRESHOLD: number = 1.5,
): void {
  // Only check when currentTime is at a heartbeat boundary.
  if (currentTime % HEARTBEAT_INTERVAL < 0.1) {
    const prevConnection = connectionRef.current;
    const connection: NavigatorConnection | undefined =
      navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;

    if (!connection?.downlink) return;

    // Always update cached connection information.
    connectionRef.current = {
      effectiveType: connection.effectiveType ?? 'not-defined',
      downlink: connection.downlink,
    };

    // Determine low bandwidth status.
    const wasLowBandwidth = prevConnection.downlink <= LOW_BANDWIDTH_THRESHOLD;
    const isLowBandwidth = connection.downlink <= LOW_BANDWIDTH_THRESHOLD;

    if (isLowBandwidth !== wasLowBandwidth) {
      gabrielAnalyticMetrics.emitEvent('VideoAdBandwidth', [
        {Name: 'LowBandwidth', Value: isLowBandwidth ? 'entered' : 'exited'},
      ]);
    }
  }
}
