export enum AdTypes {
    HtmlBanner = 500,
    Png = 501,
    Expandable = 502,
    EveFullscreenTakeover = 503,
    InhousePng = 506,
    AdamPngUp = 507,
    AdamPngDown = 508,
    AdamPngCenter = 509,
    EveFullscreen = 511,
    Video = 'Video',
    AdPod = 'AdPod',
    Google = 'Google',
    MultiBid = 'MultiBid',
    Barker = 'barker',
    Hls = 'Hls',
    DefaultTelly = -1,
  }
