import type {ReactNode} from 'react';

import {meteEnvConfig} from 'config';
import {AbstractStrategy} from 'entities/strategies/api/abstract';
import generateAdJSX from 'entities/strategies/ui/generateAdJSX';
import {AdTypes} from 'features/adoppler/enums';
import fetchAd from 'features/adoppler/service/fetch';
import {ADOPPLER_RENDER_EVENT, VIDEO_AD_FINISHED_EVENT} from 'shared/constants';
import {
  getAdFromResponse,
  logger as baseLogger,
  subscribe,
  triggerCustomEvent,
  unsubscribe,
} from 'shared/utils';

import type {AdConfig, AdStrategyName, DeviceInfo, ParsedResponse, UseAdReturnType} from 'types';

import type {AdStrategy} from 'entities/strategies/model';

interface AdopplerStrategyType extends AdStrategy {
  determineAdType: () => AdTypes
}

const logger = baseLogger.child({tag: '[AdopplerStrategy]'});

/**
 * Represents the only adoppler strategy for selecting ad types.
 *
 * @implements {AdStrategy}
 */
class AdopplerStrategy extends AbstractStrategy implements AdopplerStrategyType {
  name: AdStrategyName;
  adTypes: AdTypes[];
  timeoutId: NodeJS.Timeout | undefined;
  videoFinishHandler: (() => void) | undefined;
  emptyHandler: (() => void) | undefined;
  awaitPromise: ((result: boolean) => void) | undefined;
  loopStart: boolean = false;
  googleCanBePlayed: boolean = false;
  adTypePlaying: AdTypes | undefined = AdTypes.DefaultTelly; // type to determine what ads is playing
  startPlaying: number = Date.now(); // timestamp to determine when ads start playing
  renderAmount: number = 0; // times component were rendered during cycle tick

  /**
   * Creates an instance of the AdopplerRandomWeightedStrategy.
   *
   * @constructor
   * @param {AdTypes[] | undefined} adTypes - An array of ad types to be used by the strategy.
   * @param {DeviceInfo | null} deviceProps - DeviceProps we need to pass to handle validation
   * @param {AdConfig} adUnitConfig - application config object
   */
  constructor(adTypes: AdTypes[] | undefined, deviceProps: DeviceInfo | null, adUnitConfig: AdConfig) {
    super();
    this.name = 'adoppler-strategy';

    this.adTypes = meteEnvConfig.ads.types?.length
      ? meteEnvConfig.ads.types
      : adTypes || [AdTypes.HtmlBanner];
    this.deviceProps = deviceProps;
    this.adUnitConfig = adUnitConfig;
  }
  /**
   * Determines the selected ad type based on the weighted configuration.
   *
   * @return {AdTypes} The selected ad type.
   */
  public determineAdType(): AdTypes {
    return this.adTypes[Math.floor(Math.random() * this.adTypes.length)];
  }

  /**
   * Run and managing fetching loop
   * @param {number} fetchInterval
   */
  public startLoop(fetchInterval: number): void {
    logger.debug('Started strategy cycle');

    // Set loopStart to true, indicating the start of the ad-fetching cycle
    this.loopStart = true;

    (async () => {
      // Initialize adjusted interval with the provided fetch interval
      let adjustedInterval = fetchInterval;
      // Flag to track if a video ad is currently playing
      let videoPlaying = false;

      /**
         * Event handler triggered when a video ad finishes playing.
         * - Sets `videoPlaying` to false to indicate the video has ended.
         * - Clears any active timeout to prevent premature ad replacement.
         * - Resolves the `awaitPromise` to allow the next ad-fetch cycle to proceed.
         */
      this.videoFinishHandler = () => {
        videoPlaying = false;
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        if (this.awaitPromise) {
          this.awaitPromise(true);
        }
      };

      // Subscribe the handler to listen for the VIDEO_AD_FINISHED_EVENT
      subscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);

      // Start the main ad-fetching loop
      while (this.loopStart) {
        // Fetch an ad response
        const response = await this.getResponse();

        /**
             * If the fetched ad is a video ad, update the `videoPlaying` flag.
             * This ensures the loop tracks when a video is currently playing.
             */
        if (response.adType === AdTypes.Video) {
          videoPlaying = true;
        }

        // Run the main ad rendering process with the current interval
        await this.run(adjustedInterval, response);

        /**
             * If a video was played, increase the display interval.
             * This prevents immediate ad replacement after a video finishes,
             * allowing a smooth transition between ads.
        **/
        if (videoPlaying) {
          logger.debug('Video ad finished, increasing display interval');

          // Extend the interval by an additional 3000ms (3 seconds)
          adjustedInterval = fetchInterval + 3000;
          // Wait for 3 seconds before proceeding to the next ad fetch
          await new Promise((resolve) => setTimeout(resolve, 3000));

          // Reset the video-playing flag for the next cycle
          videoPlaying = false;
        } else {
          // If no video was played, revert to the original fetch interval
          adjustedInterval = fetchInterval;
        }
      }
    })();
  }

  /**
   * Fetch response from the cache
   * @private
   * @return {Promise<UseAdReturnType>}
   */
  private async getResponse(): Promise<UseAdReturnType> {
    return await fetchAd(this.determineAdType());
  }

  /**
   * Unsubscribe from active videoHandler
   */
  private unsubscribeVideoHandler() {
    if (this.videoFinishHandler) {
      unsubscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      this.videoFinishHandler = undefined;
    }
  }

  /**
   * Show any display ads we have in response
   * @param {Object} options - The options for sending the render event
   * @param {AdTypes} options.adType - The type of the ad to render
   * @param {ParsedResponse} [options.adSettings] - The parsed ad settings
   * @param {UseAdReturnType} [options.adResponse] - The ad response object
   * @param {number} [options.displayInterval] - The ad display interval in milliseconds
   * @return {Promise<boolean>} - A promise that resolves to a boolean indicating whether the render event was sent successfully
   * @protected
   */
  protected async sendRenderEvent(options: {
    adType: AdTypes;
    adSettings?: ParsedResponse;
    adResponse?: UseAdReturnType;
    displayInterval?: number;
  }): Promise<boolean> {
    const {adType, adSettings, adResponse, displayInterval} = options;

    this.adTypePlaying = adType;
    this.startPlaying = Date.now();

    return new Promise((resolve) => {
      this.awaitPromise = resolve;
      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
        adType: adType,
        adSettings: [adSettings],
        adResponseId: adResponse?.adResponseId,
        isAcr: adResponse?.isAcr,
      });

      if (displayInterval) {
        this.timeoutId = setTimeout(() => {
          resolve(true);
        }, displayInterval);
      }
    });
  }

  /**
   * Render Png component
   * @param {UseAdReturnType} response
   * @param {displayInterval} displayInterval
   * @protected
   */
  protected async renderPng(response: UseAdReturnType, displayInterval: number) {
    const parsedImageAd = getAdFromResponse(response.adSettings, AdTypes.Png);
    const isDeeplink = parsedImageAd?.ext?.creative_type?.action?.type === 'deeplink';
    const isDongleAvailable = false;

    if (!parsedImageAd) {
      logger.debug(`No 'DISPLAY' AD found, trying to show 'EXPANDABLE' AD`);
      return;
    }

    if (isDeeplink && !isDongleAvailable) {
      logger.debug(`Dongle is not connected. DEEPLINK Ad has been skipped.`);
      return;
    } else {
      if (this.isValid(parsedImageAd)) {
        logger.debug(`Showing 'DISPLAY'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedImageAd.adType,
          adResponse: response,
          adSettings: parsedImageAd,
          displayInterval:
            parsedImageAd.ext?.override_duration
              ? parsedImageAd.ext.override_duration * 1000
              : displayInterval,
        });
        return;
      } else {
        logger.debug(`Validation for 'DISPLAY' failed, trying to show 'EXPANDABLE' AD`);
      }
    }

    logger.debug(`Validation for 'DISPLAY' failed, trying to show 'EXPANDABLE' AD`);
  }

  /**
   * Run loop
   * @param {number} fetchInterval
   * @param {UseAdReturnType} response
   * @private
   */
  private async run(fetchInterval: number, response: UseAdReturnType) {
    /*
    await this.renderPng(response, fetchInterval);
    if (!this.adTypePlaying) {
      await this.sendRenderEvent({
        adType: AdTypes.DefaultTelly,
        displayInterval: fetchInterval,
        adResponse: {adResponseId: uuid(), adSettings: []},
      });
    }
    */

    triggerCustomEvent(ADOPPLER_RENDER_EVENT, response);

    await new Promise<void>((resolve) => {
      this.timeoutId = setTimeout(() => {
        resolve();
      }, fetchInterval);
    });
  }

  /**
   * Stop running fetching loop
   */
  public stopLoop(): void {
    this.loopStart = false;
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    this.unsubscribeVideoHandler();

    // show default logo
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, {adType: AdTypes.DefaultTelly, adSettings: []});
  }

  /**
   * Retrieves an ad using the Adoppler random weighted strategy.
   *
   * @return {ReactNode | null} The JSX element representing the selected ad.
   */
  public getAd(): ReactNode | null {
    return generateAdJSX(this.name, this.startLoop.bind(this), this.stopLoop.bind(this), this.googleCanBePlayed);
  }
}

export {AdopplerStrategy};
