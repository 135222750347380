import type {LDInspectionFlagDetailChangedHandler} from 'launchdarkly-react-client-sdk';
import type {Logger} from 'pino';

/**
 * Updates the flag value and logs the change.
 *
 * This function is intended to handle a feature flag changes.
 * It logs the new value of the flag and can be extended to include logic for caching the flag.
 *
 * @param {string} flagKey - The key of the feature flag that has changed.
 * @param {Record<string, unknown>} value - The new value of the feature flag.
 * @param {Logger} logger - The logger from parent.
 */
function updateFlag(flagKey: string, value: Record<string, unknown>, logger: Logger) {
  logger.info(`[Launch Darkly Flags] Flag "${flagKey}" changed:`, value);
}

const flagDetailChangedInspector = (logger: Logger) => ({
  type: 'flag-detail-changed',
  name: 'FlagDetailChangeInspector',
  synchronous: true,
  method: (flagKey, detail) => {
    updateFlag(flagKey, detail.value, logger);
  },
} as LDInspectionFlagDetailChangedHandler);

export {
  updateFlag,
  flagDetailChangedInspector,
};

export const extractFwData = (buildId?: string): { fw_version: string; fw_build: string } => {
  if (!buildId) {
    return {fw_version: '', fw_build: ''};
  }

  const regex = /([\d]+\.[\d]+\.[\d]+)-([\d]+)/;
  const match = buildId.match(regex);

  return match
    ? {fw_version: match[1], fw_build: match[2]}
    : {fw_version: '', fw_build: ''};
};
