import {useState, useEffect, useRef} from 'react';

import {v4 as uuid} from 'uuid';

import {useDebugMute} from 'components/Ads/TypeVideo/helpers/useDebugMute';
import {gabrielAnalyticMetrics, gabrielVideoMetrics} from 'shared/api/metrics-service';
import VideoJsPlayer from 'shared/components/VideoPlayer/VideoJsPlayer';
import {VIDEO_AD_FINISHED_EVENT} from 'shared/constants';
import {
  analytics,
  logger as baseLogger,
  triggerCustomEvent,
} from 'shared/utils';
import {globalStorage} from 'shared/utils/globalStorage.ts';

import type {HlsItem} from 'types';

import type {TrackProps} from 'react-player/file';
import type {AndroidSDKEvent} from 'shared/utils/eventsSdk';

interface HlsProps {
  urls: HlsItem[];
  sdkEvent: AndroidSDKEvent;
  videoConfig: {
    mute?: boolean,
    autoplay?: number,
  },
}

const logger = baseLogger.child({tag: '[TypeHls Component]'});

export const TypeHls = ({urls, sdkEvent, videoConfig}: HlsProps) => {
  const [hls, setUrl] = useState<HlsItem | undefined>(urls.shift());
  const mute = useDebugMute();
  const isVideoReadyToPlay = useRef(false);
  const analyticState = useRef({impressionSent: false});
  const errorRef = useRef<unknown>(null);
  const uid4 = uuid();

  /**
   * Trigger analytic error, in case no adImpression sent
   */
  const triggerAnalyticError = () => {
    if (!analyticState.current.impressionSent) {
      analytics.emitAdEvent('Error', {
        ad_error_code: `error`,
        ad_error_log_response: 'error while loading video',
        ad_error_source: 'elemental',
      });
      gabrielAnalyticMetrics.emitEvent('AdError', [{Name: 'type', Value: 'TypeVideo'}]);
    }
  };

  useEffect(() => {
    logger.info(`Received ${JSON.stringify(urls)} sources to play`);
  }, [urls]);

  // the only way we send analytic event when bid changed
  useEffect(() => {
    analyticState.current.impressionSent = false;
    /*
    analytics.emitAdEvent('Request', {
      ad_server: 'elemental',
    });
    */

    gabrielAnalyticMetrics.emitEvent('AdRequest', [{Name: 'type', Value: 'Video'}]);
  }, [hls?.stream]);

  if (!hls || !hls.stream) return null;

  return (
    <div key={uid4}>
      <VideoJsPlayer
        data-testid='ad-video'
        playing={!!videoConfig.autoplay}
        tracks={[] as TrackProps[]}
        url={hls.stream}
        muted={mute && Boolean(videoConfig.mute)}
        onStart={() => {
          sdkEvent.adStarted('Video', uid4);
          /*
          gabrielAnalyticMetrics.emitEvent('VideoAdStart', [
            {Name: 'type', Value: 'Video'},
          ]);
          */
          gabrielVideoMetrics.emitEvent('VideoAdClipPlayback', [{Name: 'position', Value: 'start'}]);
          globalStorage.nonceManager?.sendPlaybackStart();
        }}
        onReady={() => {
          isVideoReadyToPlay.current = true;
        }}
        onEnded={() => {
          globalStorage.nonceManager?.sendPlaybackEnd();
          gabrielVideoMetrics.emitEvent('VideoAdClipPlayback', [{Name: 'position', Value: 'finish'}]);

          // play next video from the array
          if (urls.length > 0) {
            setUrl(urls.shift());
          } else {
            triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: false});
          }
        }}
        onProgress={() => {
          // logger.info(`Played ${progress} s`);
          // vastTracker.setProgress(progress.playedSeconds, defaultMacros);
          // const playedSeconds = Math.trunc(progress.playedSeconds);
          // const progressDuration = videoProgress(playedSeconds, videoDurationSec);
          // if (progressDuration) {
          //  gabrielVideoMetrics.emitEvent('VideoAdClipPlayback', [{Name: 'position', Value: progressDuration}]);
          // }
          /*
        if (isImpressionShouldBeSent(analyticState.current.impressionSent, progressDuration)) {
          analyticState.current.impressionSent = true;

          analytics.emitAdEvent('Impression', {
            bid_request_id: bid.id,
            advertiser_name: bid.adomain?.length ? bid.adomain[0] : undefined,
            ad_server: 'elemental',
            creative_id: bid.crid,
            creative_permutation_type: bid.ext?.creative_type?.ad_creative_type_name,
          });

            gabrielAnalyticMetrics.emitEvent('AdImpression', [
              {Name: 'type', Value: 'Video'},
              {Name: 'source', Value: 'Elemental'},
              {Name: 'status', Value: 'paid'},
            ]);
          }
          */
        }}
        onError={(error) => {
          logger.error(error);
          if (!errorRef.current) {
            globalStorage.nonceManager?.sendPlaybackEnd();
            triggerAnalyticError();
            triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: true});
            errorRef.current = error;
          }
        }}
      />
    </div>
  );
};
