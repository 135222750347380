
import VideoJsPlayer from 'shared/components/VideoPlayer/VideoJsPlayer';
import VideoPlayer from 'shared/components/VideoPlayer/VideoPlayer';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';

import type {FlagSet} from 'shared/utils/launch-darkly-service/types';

/**
 * Returns the selected video player component based on the feature flag.
 *
 * This hook checks the flag "video-component-video-library" from LaunchDarklyService and
 * returns either the ReactPlayer-based VideoPlayer or the Video.js-based VideoJsPlayer.
 *
 * @param {string} flag - Related to video component flag name.
 * @return {React.ComponentType} The selected video player component.
 */
export default function useSelectedPlayer(
  flag: 'video-component-video-library' | 'video-component-library-smart-screen',
) {
  const videoComponentLibrary = LaunchDarklyService.getFlag(flag as keyof FlagSet);

  return videoComponentLibrary === 'react-player' ? VideoPlayer : VideoJsPlayer;
}
