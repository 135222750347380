import {useEffect, useState, type ReactNode} from 'react';

import TellyAd from 'components/Ads/Telly';
import {useAdUnitConfigContext, useAppContext} from 'context';
import {
  AdopplerStrategy,
  AdopplerMultiBidStrategy,
  AdopplerBarkerStrategy,
} from 'entities/strategies';
import {useChannelData} from 'shared/api/bootstrap-service';
import {useAdStrategyManager} from 'shared/hooks';
import {handleCustomParameters, logger as baseLogger} from 'shared/utils';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';

const logger = baseLogger.child({tag: '[StrategyManager]'});

// Current version of the Gabriel application
logger.info('Running gabriel version', {
  version: __APP_VERSION__,
  hash: import.meta.env.VITE_GIT_COMMIT_HASH,
});

/**
 * The unhandledrejection event is sent to the global scope of a script
 * when a JavaScript Promise that has no rejection handler is rejected;
 *
 * This is useful for debugging and for providing fallback error handling for unexpected situation
 * @param {PromiseRejectionEvent} event handled rejection
 */
window.onunhandledrejection = (event) => {
  logger.info('Caught unhandled exception', event.reason, event.promise);
};

/**
 * Handle url params
 */
handleCustomParameters();

/**
 * The StrategyManager component encapsulates the creation of strategy
 * instances and delegation to the strategy manager hook.
 *
 * This allows us to initialize and configure the strategies in one
 * place and keep the component reusable across the app.
 *
 * The strategy manager will select one of the provided strategy instances
 * to use. That chosen strategy will determine the ad type to display.
 *
 * @see docs
 * {@link https://github.com/TeeVeeCorp/gabriel/pull/74}
 * {@link https://teevee.atlassian.net/browse/TV1-8124}
 *
 * @return {ReactNode | null}
 */
export const StrategyManager = (): ReactNode | null => {
  const {adUnitConfig} = useAdUnitConfigContext();
  const {deviceProperties} = useAppContext();
  const {data: channelData} = useChannelData();

  logger.debug('Initialized AdUnitConfig in StrategyManager', adUnitConfig, channelData);

  const adTypes = adUnitConfig?.providers.gabriel?.adoppler.adTypes ?? [];
  // Create configured strategy instances
  const strategies = [
    new AdopplerBarkerStrategy(channelData, adUnitConfig),
    new AdopplerStrategy(adTypes, deviceProperties, adUnitConfig),
    new AdopplerMultiBidStrategy(deviceProperties, adUnitConfig),
  ];

  // Delegate ad selection to strategy manager
  const strategy = useAdStrategyManager(strategies, channelData);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    logger.info('Running with strategy', strategy?.name);
  }, [strategy?.name]);

  // Initializing of a LaunchDarkly service.
  useEffect(() => {
    if (deviceProperties) {
      LaunchDarklyService.getInstance(deviceProperties)
        .then(() => setReady(true))
        .catch((error) => logger.error('Failed to initialize LaunchDarkly service', {error}));
    }
  }, [deviceProperties]);

  // Render ad from selected strategy
  return ready && strategy ? strategy.getAd() : <TellyAd loaded={false} reason='startup' />;
};
