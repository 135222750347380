import {createContext} from 'react';

import type {AdConfig} from 'types';

import type {UserBodyRequest} from 'features/adoppler';

export interface AdUnitConfigContextType {
  adUnitConfig: AdConfig;
  eids?: UserBodyRequest
}

export const AdUnitConfigContext = createContext<AdUnitConfigContextType>({
  adUnitConfig: {} as AdConfig,
});

AdUnitConfigContext.displayName = 'AdUnitConfigContext';
