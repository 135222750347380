import type {ReactNode} from 'react';

import {useSoundMute} from 'components/Ads/TypeVideo/helpers';

import {MuteVideoContext} from './MuteVideoContext';

const MuteVideoContextProvider = ({children}: {children: ReactNode}): ReactNode | null => {
  const {isSoundMuted} = useSoundMute();

  return (
    <MuteVideoContext.Provider value={{isSoundMuted}}>
      {children}
    </MuteVideoContext.Provider>
  );
};

export default MuteVideoContextProvider;
